import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import {
  Button,
  Divider,
  FormControl,
  Grid,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { ActionButton } from 'components';
import { useAuth } from 'contexts';
import { ErrorIndicatorIcon, googleImg, togglePasswordImg } from 'resources';

import { useStyles } from './LoginPage.css';
import { getValidationSchema } from './validation';

const InputField = ({ id, label, placeholder, error }) => {
  const classes = useStyles();

  const [ isPasswordShown, setIsPasswordShown ] = useState(id !== 'password');

  return (
    <Grid container direction='column' justify='center'>
      <label htmlFor={id} className={classes.label}>{label}</label>
      <Field
        id={id}
        name={id}
        type={isPasswordShown ? 'text' : 'password'}
        placeholder={placeholder}
        className={clsx({
          [classes.input]: true,
          [classes.inputHasError]: error
        })} />
      {id === 'password' &&
        <img className={classes.togglePasswordImg} src={togglePasswordImg} alt='toggle-password-img' onClick={() => setIsPasswordShown(!isPasswordShown)} />
      }
      <div className={classes.errorMsg}>
        <ErrorMessage name={id} />
      </div>
    </Grid>
  );
};

export const LoginPage = () => {
  const classes = useStyles();

  const { googleUnAuthMsg, login, loginGoogle } = useAuth();
  const history = useHistory();

  const [ isError, setIsError ] = useState(false);
  const loginFormRef = useRef();

  const handleLogin = async (email, pass) => {
    setIsError(false);
    try {
      await login(email, pass);
      history.replace('/');
    } catch (error) {
      setIsError(true);
    }
  };

  const handleLoginWithGoogle = async () => {
    setIsError(false);
    try {
      await loginGoogle();
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    if (googleUnAuthMsg && loginFormRef) {
      loginFormRef.current.resetForm();
    }
  }, [googleUnAuthMsg]);

  return (
    <Grid className={classes.background}>
      <Formik
        initialValues={{email: '', password: ''}}
        validationSchema={getValidationSchema}
        onSubmit={(values) => handleLogin(values.email, values.password)}
        innerRef={loginFormRef}>
        {({ errors }) => (
          <Form className={classes.contentWrapper}>
            <Grid container alignItems='center' justify='center' direction='column'>
              <Typography variant='h1'>Log in</Typography>
              {isError &&
                <Alert severity='error' icon={<ErrorIndicatorIcon/>} className={classes.errorPopup} onClose={() => setIsError(false)}>
                  <Typography className={classes.errorPopupText}>
                    Sorry we don’t have account with entered email or password in our records.
                  </Typography>
                </Alert>
              }
              <FormControl error={errors['email']} style={{ marginTop: 30 }}>
                <InputField id='email' label='E-mail*' placeholder='Enter your email address' error={errors['email']} />
              </FormControl>
              <FormControl error={errors['password']} style={{ marginTop: 20 }}>
                <InputField id='password' label='Password*' placeholder='Enter your password' error={errors['password']} />
              </FormControl>
              <ActionButton variant='primary' type='submit' text='Log In' className={classes.loginButton} />
              <div className={classes.separator}>
                <Divider className={classes.dividerStyles} />
                <Typography className={classes.separatorText}>OR</Typography>
                <Divider className={classes.dividerStyles} />
              </div>
              <Button onClick={handleLoginWithGoogle} variant='outline' className={classes.googleButton}>
                <Grid container alignItems='center' justify='center'>
                  <img src={googleImg} alt='google icon'/>
                  <Typography className={classes.signUpWithGoogleText}>Sign in with Google</Typography>
                </Grid>
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
      <footer className={classes.footer}>
        <Typography className={classes.footerText}>
          NFI Industries @ { new Date().getFullYear() }. All rights reserved.
        </Typography>
      </footer>
    </Grid>
  );
};